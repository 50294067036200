<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide      
                v-if="resources!=null"          
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row> -->

        <br>
      
      <!--   <h1 class="titles">
          
          <span style="color: #d82125">N</span>
          <span style="color: #d02175">o</span>
          <span style="color: #f6c921">r</span>
          <span style="color: #d02175">m</span>
          <span style="color: #4d944a">a</span>
          <span style="color: #07aaba">t</span>
          <span style="color: #d82125">i</span>
          <span style="color: #d02175">v</span>
          <span style="color: #f6c921">i</span>
          <span style="color: #d02175">d</span>
          <span style="color: #07aaba">a</span>
          <span style="color: #4d944a">d</span>
          

        </h1> -->

        
        <h1 class="titles">
          <span style="color: #8b8c8c">Zona Arqueologica</span>
        </h1>
<br><br>

    
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="5" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/calzada.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/atetelco.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/luna.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/sol.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/museo.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/quetzalcoatl.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/quetzalpapalotl.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/tetitla.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/8%20zona/yayahuala.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>

                    

<h4 class="titles">
          <span style="color: #8b8c8c">Historia del sitio</span>
        </h4>

        <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="8"  xl="7" class="mt-4">
        <p>
          <b>
Teotihuacán es el sitio más explorado de Mesoamérica, ya que los primeros trabajos con fines de investigación datan del siglo XVIII, cuando Don Carlos de Sigüenza y Góngora exploró el edificio adosado a la Pirámide de la Luna. A finales del siglo XIX, bajo la figura de la Inspección General de Monumentos de la República, Don Leopoldo Batres y Huerta realiza exploraciones en diversos edificios aledaños a la Calzada de los Muertos, descubriendo murales y esculturas, y en 1905 emprendió trabajos en la Pirámide del Sol, bajo los auspicios del gobierno de Porfirio Díaz, para la conmemoración del Primer Centenario de la Independencia del país, impulsando el desarrollo de las disciplinas antropológicas.  A partir de la intervención de la Pirámide del Sol se adquirieron terrenos  por parte del gobierno federal para la apertura oficial de la primera zona arqueológica y museo de sitio de México. 

          </b>
        </p>
        <p>
          <b>
           Posteriormente el desarrollo de los trabajos de la Dirección de Antropología bajo el liderazgo de Manuel Gamio en 1917, representa una de las experiencias más enriquecedoras en el trabajo social, la investigación arqueológica y el impulso para el progreso regional, que reflejó el ímpetu pos revolucionario que daría forma a muchas de las instituciones aún vigentes en el Estado contemporáneo mexicano. 
          </b>
        </p>
        <p>
          <b>
En este sitio arqueológico se han desarrollado 2 de los proyectos de investigación más grandes en la historia de México: el Proyecto “Teotihuacán 1962-1964” y el proyecto “Teotihuacán 1980-1982”, además de uno de los megaproyectos planteados durante la década de los años noventa por el gobierno federal, conocido como “Teotihuacán 1992-1994”. La investigación en el sitio es permanente  e interdisciplinaria, pues prácticamente todas las disciplinas antropológicas encuentran temas de trabajo en el sitio, el cual es objeto de estudio tanto por instituciones académicas nacionales como internacionales.

          </b>
        </p>
        <p>
          <b>
Teotihuacán se ha convertido en un estandarte para los mexicanos en la defensa y resguardo del Patrimonio Cultural Nacional, su monumento principal, la Pirámide del Sol, es un icono de la identidad nacional, sobre todo como un elemento relevante del pasado prehispánico. Los restos arqueológicos de la antigua ciudad del Clásico en Teotihuacán, representan a México en el ámbito internacional, ya que fue el primer sitio de nuestro país declarado tesoro del Patrimonio Mundial, por la UNESCO en 1987.

          </b>
        </p>

        

<h4 class="titles">
          <span style="color: #8b8c8c">Descripción del sitio </span>
        </h4>
        <br>

        <p>
          <b>
El área abierta a la visita pública tiene una extensión de 264 hectáreas,  donde se concentran los principales complejos de edificios monumentales, como La Ciudadela y el Templo de la Serpiente Emplumada, la Calzada de los Muertos y los conjuntos residenciales que la flanquean, las Pirámides del Sol y la Luna, el Palacio de Quetzalpapálotl y 4 conjuntos departamentales con importantes ejemplos de pintura mural, como son Tetitla, Atetelco, Tepantitla y La Ventilla, además de otros 2 conjuntos de corte habitacional denominados Yayahuala y Zacuala. 

          </b>
        </p>

         <p>
          <b>
La Zona arqueológica cuenta con dos museos especializados: el de la Cultura Teotihuacana y el Museo de Murales Teotihuacanos “Beatriz de la Fuente”, así como una sala de exposiciones temporales ubicada en el edificio conocido como “ex-museo”. Otras áreas en las que se exponen piezas arqueológicas son el Jardín Escultórico y el jardín al sur del Río San Juan; además se cuenta con un jardín botánico de la flora tradicional, un teatro al aire libre y el edificio sede del centro de Estudios Teotihuacanos.   

          </b>
        </p>

         <p>
          <b>
La entrada a la zona puede realizarse por 5 puertas, distribuidas e interconectadas por un camino periférico empedrado que circunda el área monumental,  además hay caminos vecinales que la comunican con los conjuntos habitacionales mencionados.

          </b>
        </p>

      </b-col>
    </b-row>
 <br><br>





  
    </div>
</template>


<script>
import Flipbook from "flipbook-vue";
import axios from 'axios';

export default {
  components: {
    Flipbook,
  },
  data() {
    return {
      resources: null,
      datos:[
       

      ]
    };
  },
  mounted: function(){
     
    },
  computed: {

    pages: function () {
        
        let pages = [null,]//null primera pagina para que solo aparezca la portada

        for (let i = 1; i <= this.resources.numero_paginas_bando; i++) {
              let image_num = i.toString().padStart(4,0)        
              pages.push('/files/bando/'+this.resources.prename_paginas_bando+image_num+'.jpg')
        }
        return pages;
      }
   }
}
</script>

<style>
.flipbook .viewport {
    width: 90vw;
    height: 90vh;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

</style>